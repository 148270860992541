import React, { useState, useEffect } from 'react';
import { getSubscriptionList } from "../services/tenant-identity-service";
import { Link } from 'react-router-dom';
import '../styles/loader.scss';
import Loader from '../images/transparent-loader.png';
import DataGrid, {
    Column, Pager,
    Paging,
    SearchPanel, Export,
    FilterRow
} from 'devextreme-react/data-grid';

function Subscribe(props) {
    const [SubscribeList, setSubscribeList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const pageSizes = [10, 25, 50, 100];

    useEffect(() => {
        getSubscribe();
        return () => {
        };

    }, []);

    const getSubscribe = async () => {
        try {
            setLoading(true);
            let response = await getSubscriptionList();
            setSubscribeList(response);
            setLoading(false);
        } catch (error) {
            console.log('getFreeTrial error', error);
            setLoading(false);
        }
    }

    const calculateSerialNo = (cellInfo) => {
        return cellInfo.rowIndex + 1;
    }



    return (
        <div className="App">
            <div className="logout_btn">
                <Link to='/ServiceList'>
                    <div className="login_submit_button text-center">Back</div>{' '}
                </Link>
            </div>

            <DataGrid
                dataSource={SubscribeList}
                focusedRowEnabled={true}
                allowColumnResizing={true}
                wordWrapEnabled={true}
                allowColumnReordering={true}
                keyExpr="subscribeGuid"
                autoNavigateToFocusedRow={true}
                showBorders={true}>
                <Paging enabled={false} />
                <Export enabled={true} />
                <FilterRow visible={true} />
                <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                <Paging defaultPageSize={50} />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Column
                    caption="SI NO"
                    cellRender={calculateSerialNo}
                />
                <Column
                    dataField="email"
                    caption="Email"
                />
                <Column
                    dataField="createdDate"
                    caption="Created Date"
                    dataType="datetime"
                />
            </DataGrid>

            {
                isLoading ? (
                    <img src={Loader} alt="" className="loader" />
                ) : null
            }
        </div>
    )
}

export default Subscribe
