import React, { Component } from 'react';
import { Fragment } from 'react';


export default class ErrorAlert extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showAlertMessage: false
        }
    }

    componentDidMount = () => {
        if (this.props.message !== null) {
            this.setState({ showAlertMessage: true })
        }

        setTimeout(() => this.setState({ showAlertMessage: false }), 3000);
    }

    render() {
        
        return (
            <Fragment>
                {
                    this.state.showAlertMessage ? (
                        <div class="alert alert-danger" role="alert" style={{ marginTop: "40px" }}>
                            {this.props.message}
                        </div>
                    ) : null
                }
            </Fragment>
        )
    }
};