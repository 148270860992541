import jwt_decode from 'jwt-decode';

const getDecodedTokenDetails = () => {
    let token = localStorage.getItem('authenticationToken');

    if (token !== '' && token !== undefined && token !== null) {
        let decodedToken = jwt_decode(token);

        let email = decodedToken['email'];
        let empl_id = decodedToken['custom:empl_id'];
        let userGuid = decodedToken['custom:userGuid'];
        let userName = decodedToken['custom:userName'];
        let userRole = decodedToken['custom:userRole'];

        return { email, empl_id, userGuid, userName, userRole };
    } else {
        return null;
    }
}

export  {
    getDecodedTokenDetails
}