import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import React, { Fragment, useState } from 'react';
import Button from 'devextreme-react/button';
import {
    Validator,
    RequiredRule,
    CompareRule,
    EmailRule,
} from 'devextreme-react/validator';

import notify from 'devextreme/ui/notify';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { Auth, Amplify } from "aws-amplify";
import { MessageType } from '../constant/message_type_enum';
import "../styles/change_password.scss";
import { Link } from 'react-router-dom';

import awsPoolConfiguration from "../aws-exports";
const initialFormValue = {
    email: "",
    confirmationCode: "",
    newPassword: ""
}

const REACT_APP_USERPOOL_REGION = process.env.REACT_APP_USERPOOL_REGION;
const REACT_APP_APPCLIENT_ID = process.env.REACT_APP_APPCLIENT_ID;
const REACT_APP_IDENTITY_POOL_ID = process.env.REACT_APP_IDENTITY_POOL_ID;
const REACT_APP_USERPOOL_ID = process.env.REACT_APP_USERPOOL_ID;

function ForgotPassword(props) {
    const [formValues, setformValues] = useState(initialFormValue);
    const [codeSent, setCodeSent] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);
    const [isSendingCode, setIsSendingCode] = useState(false);
    const [loadIndicatorVisible, setLoadIndicatorVisible] = useState(false);
    let [newPasswordMode, setNewPasswordMode] = useState('password');
    let [confirmPasswordMode, setConfirmPasswordMode] = useState('password');
    let [newPasswordButton, setNewPasswordButton] = useState({
        icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7klEQVRYw+2YP0tcQRTFz65xFVJZpBBS2O2qVSrRUkwqYfUDpBbWQu3ELt/HLRQ/Q8RCGxVJrRDEwj9sTATxZ/Hugo4zL/NmV1xhD9xi59177pl9986fVwLUSyi/tYC+oL6gbuNDYtyUpLqkaUmfJY3a+G9JZ5J2JW1J2ivMDBSxeWCfeBxYTHSOWMcRYLOAEBebxtEVQWPASQdi2jgxro4E1YDTQIJjYM18hszGbew4EHNq/kmCvgDnHtI7YBko58SWgSXg1hN/btyFBM0AlwExczG1YDZrMS4uLUeUoDmgFfjLGwXEtG05wNXyTc4NXgzMCOAIGHD8q0ATuDZrempkwGJ9+AfUQ4K+A/eEseqZ/UbgdUw4fqs5vPeW+5mgBvBAPkLd8cPju+341P7D/WAaJGCdOFQI14kr6o/zvBKZYz11L5Okv5KGA89Kzu9K0b0s5ZXt5PjuOL6TRV5ZalFP4F+rrnhZ1Cs5vN6ijmn7Q162/ThZq9+YNW3MbfvDAOed5cxdGL+RFaUPKQtjI8DVAr66/u9i6+jJzTXm+HFEVqxVYBD4SNZNKzk109HxoycPaG0bIeugVDTp4hH2qdXJDu6xOAAWiuQoQdLHhvY1aEZSVdInG7+Q9EvSz9RrUKqgV0PP3Vz7gvqCOsUj+CxC9LB1Dc8AAAASdEVYdEVYSUY6T3JpZW50YXRpb24AMYRY7O8AAAAASUVORK5CYII=',
        type: 'default',
        onClick: () => {
            if (newPasswordMode === "text") {
                setNewPasswordMode("password");
                newPasswordMode = "password"
            } else {
                setNewPasswordMode("text");
                newPasswordMode = "text"
            }
        },
    });
    let [confirmPasswordButton, setConfirmPasswordButton] = useState({
        icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7klEQVRYw+2YP0tcQRTFz65xFVJZpBBS2O2qVSrRUkwqYfUDpBbWQu3ELt/HLRQ/Q8RCGxVJrRDEwj9sTATxZ/Hugo4zL/NmV1xhD9xi59177pl9986fVwLUSyi/tYC+oL6gbuNDYtyUpLqkaUmfJY3a+G9JZ5J2JW1J2ivMDBSxeWCfeBxYTHSOWMcRYLOAEBebxtEVQWPASQdi2jgxro4E1YDTQIJjYM18hszGbew4EHNq/kmCvgDnHtI7YBko58SWgSXg1hN/btyFBM0AlwExczG1YDZrMS4uLUeUoDmgFfjLGwXEtG05wNXyTc4NXgzMCOAIGHD8q0ATuDZrempkwGJ9+AfUQ4K+A/eEseqZ/UbgdUw4fqs5vPeW+5mgBvBAPkLd8cPju+341P7D/WAaJGCdOFQI14kr6o/zvBKZYz11L5Okv5KGA89Kzu9K0b0s5ZXt5PjuOL6TRV5ZalFP4F+rrnhZ1Cs5vN6ijmn7Q162/ThZq9+YNW3MbfvDAOed5cxdGL+RFaUPKQtjI8DVAr66/u9i6+jJzTXm+HFEVqxVYBD4SNZNKzk109HxoycPaG0bIeugVDTp4hH2qdXJDu6xOAAWiuQoQdLHhvY1aEZSVdInG7+Q9EvSz9RrUKqgV0PP3Vz7gvqCOsUj+CxC9LB1Dc8AAAASdEVYdEVYSUY6T3JpZW50YXRpb24AMYRY7O8AAAAASUVORK5CYII=',
        type: 'default',
        onClick: () => {
            if (confirmPasswordMode === 'text') {
                setConfirmPasswordMode('password');
                confirmPasswordMode = 'password';
            } else {
                setConfirmPasswordMode('text');
                confirmPasswordMode = 'text';
            }
        },
    });


    const onUserNameChanged = (e) => {
        if (e.value != '') {
            let formValueObj = { ...formValues };
            formValueObj.email = e.value;
            setformValues(formValueObj);
        }
    }

    const onConfirmationCodeChanged = (e) => {
        if (e.value != '') {
            let formValueObj = { ...formValues };
            formValueObj.confirmationCode = e.value;
            setformValues(formValueObj);
        }
    }

    const onNewPasswordChanged = (e) => {
        if (e.value != '') {
            let formValueObj = { ...formValues };
            formValueObj.newPassword = e.value;
            setformValues(formValueObj);
        }
    }

    async function sentVerificationCode(event) {
        try {
            event.preventDefault();
            setCodeSent(true);



            setIsSendingCode(true);
            setLoadIndicatorVisible(true);
            setLoadIndicatorVisible(false);
            setCodeSent(true);

            awsPoolConfiguration.aws_project_region = REACT_APP_USERPOOL_REGION;
            awsPoolConfiguration.aws_cognito_identity_pool_id = REACT_APP_IDENTITY_POOL_ID;
            awsPoolConfiguration.aws_cognito_region = REACT_APP_USERPOOL_REGION;
            awsPoolConfiguration.aws_user_pools_id = REACT_APP_USERPOOL_ID;
            awsPoolConfiguration.aws_user_pools_web_client_id = REACT_APP_APPCLIENT_ID;

            Amplify.configure(awsPoolConfiguration);

            Auth.forgotPassword(formValues.email).then(async (data) => {
                let result = data;
                let deliveryMedium = result.CodeDeliveryDetails.DeliveryMedium;
                let destination = result.CodeDeliveryDetails.Destination;
                let otpVerificationMessage = "";

                if (deliveryMedium === 'SMS') {
                    otpVerificationMessage = "OTP has been sent to your registered mobile number " + destination;
                } else if (deliveryMedium === 'EMAIL') {
                    otpVerificationMessage = "OTP has been sent to your registered email " + destination;
                }

                displayCustomMessage(otpVerificationMessage, MessageType.SUCCESS);
                setLoadIndicatorVisible(false);
                setCodeSent(true);
            }).catch((err) => {
                console.log('forgotPassword error', err);
                displayCustomMessage(err.message, MessageType.ERROR);
                setLoadIndicatorVisible(false);
                setIsSendingCode(false);
            })
        } catch (error) {
            console.log('sentVerificationCode error+++++++', JSON.stringify(error));
            setIsSendingCode(false);
            displayCustomMessage(error.message, MessageType.ERROR);
            setLoadIndicatorVisible(false);
        }
    }

    async function confirmVerificationCode(event) {
        try {
            event.preventDefault();
            setIsConfirming(true);
            setLoadIndicatorVisible(true);
            let otpConfirmationMessage = "";

            Auth.forgotPasswordSubmit(
                formValues.email,
                formValues.confirmationCode,
                formValues.newPassword
            ).then(async (data) => {
                setConfirmed(true);
                otpConfirmationMessage = 'Password Reset Successfully';
                displayCustomMessage(otpConfirmationMessage, MessageType.SUCCESS);
                setLoadIndicatorVisible(false);
                props.history.push('/login');
            }).catch((err) => {
                console.log('confirmation err+++++', err);
                setIsConfirming(false);
                displayCustomMessage(err.message, MessageType.ERROR);
                setLoadIndicatorVisible(false);
            });
        } catch (error) {
            console.log('sentVerificationCode error+++++++', JSON.stringify(error));
            setIsSendingCode(false);
            displayCustomMessage(error.message, MessageType.ERROR);
            setLoadIndicatorVisible(false);
        }
    }

    function renderVerificationCodeForm() {
        return (
            <form onSubmit={sentVerificationCode}>
                <div className="change_password_container">
                    <div className="dx-fieldset width_50_percent">
                        <div className="dx-fieldset-header">Forgot Password</div>
                        <div className="dx-field">
                            <div className="dx-field-label">UserName</div>
                            <div className="dx-field-value">
                                <TextBox value={formValues.email}
                                    onValueChanged={onUserNameChanged}>
                                    <Validator>
                                        <RequiredRule message="Email is required" />
                                        <EmailRule message="Email is invalid" />
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                    </div>

                    <div className="dx-fieldset">
                        <Button
                            id="button"
                            text="Submit"
                            type="success"
                            width={180}
                            height={40}
                            useSubmitBehavior={true} >
                            <LoadIndicator className="button-indicator" visible={loadIndicatorVisible} />
                            <span className="dx-button-text">Submit</span>
                        </Button>
                    </div>
                </div>
            </form>
        );
    }

    const passwordComparison = () => {
        return formValues.newPassword;
    }

    const renderConfirmationForm = () => {
        return (
            <form onSubmit={confirmVerificationCode}>
                <div className="change_password_container">
                    <div className="dx-fieldset width_50_percent">
                        <div className="dx-fieldset-header">Forgot Password</div>
                        <div className="dx-field">
                            <div className="dx-field-label">Confirmation Code</div>
                            <div className="dx-field-value">
                                <TextBox
                                    value={formValues.confirmationCode}
                                    onValueChanged={onConfirmationCodeChanged}>
                                    <Validator>
                                        <RequiredRule message="Confirmation Code is required" />
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                        <div className="dx-field">
                            <div className="dx-field-label">New Password</div>
                            <div className="dx-field-value">
                                <TextBox
                                    mode={newPasswordMode}
                                    value={formValues.newPassword}
                                    onValueChanged={onNewPasswordChanged}>
                                    <TextBoxButton
                                        name="password"
                                        location="after"
                                        options={newPasswordButton}
                                    />
                                    <Validator>
                                        <RequiredRule message="New Password is required" />
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                        <div className="dx-field">
                            <div className="dx-field-label">Confirm Password</div>
                            <div className="dx-field-value">
                                <TextBox mode={confirmPasswordMode}>
                                    <TextBoxButton
                                        name="password"
                                        location="after"
                                        options={confirmPasswordButton}
                                    />
                                    <Validator>
                                        <RequiredRule message="Confirm Password is required" />
                                        <CompareRule message="New Password and Confirm Password do not match" comparisonTarget={passwordComparison} />
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                    </div>

                    <div className="dx-fieldset">
                        <Button
                            id="button"
                            text="Submit"
                            type="success"
                            width={180}
                            height={40}
                            useSubmitBehavior={true} >
                            <LoadIndicator className="button-indicator" visible={loadIndicatorVisible} />
                            <span className="dx-button-text">Submit</span>
                        </Button>
                    </div>
                </div>
            </form>
        );
    }

    const displayCustomMessage = (message, messageType) => {
        notify({
            message: message,
            position: {
                my: 'center top',
                at: 'center top',
            },
        }, messageType, 5000);
    }

    return (
        <Fragment>
            <div className="App">
                <div className="logout_btn">
                    <Link to='/login'>
                        <div className="login_submit_button text-center">Back</div>
                    </Link>
                </div>
                <div>
                    {!codeSent
                        ? renderVerificationCodeForm()
                        : renderConfirmationForm()}
                </div>
            </div>
        </Fragment>
    )
}


export default ForgotPassword;
