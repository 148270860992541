import React, { Component } from 'react';
import "../styles/loginpage.scss";
import "../styles/signin.scss";
import Form from "react-bootstrap/Form";
import { Auth, Amplify } from 'aws-amplify';
import SuccessAlert from './SuccessAlert';
import ErrorAlert from './ErrorAlert';
import "bootstrap/dist/css/bootstrap.min.css";
import Tagline from '../images/Tagline.svg';
import StellosysLogo from '../images/StellosysLogo.svg';
import Logo from '../images/Logo.svg';
import Loader from '../images/Loader.gif';
import awsPoolConfiguration from '../aws-exports';

let region = process.env.REACT_APP_USERPOOL_REGION;
let userpoolID = process.env.REACT_APP_USERPOOL_ID;
let identityPoolId = process.env.REACT_APP_IDENTITY_POOL_ID;
let appClientID = process.env.REACT_APP_APPCLIENT_ID;

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            newPassword: '',
            isFirstTimeLogin: false,
            isLoading: false,
            alert_type: '',
            alert_message: ''
        };
    }

    componentDidMount() {
        let mode = process.env.REACT_APP_ENV_FILE_NAME;
        console.log('mode.......', mode);
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    clearAlertType = () => {
        setTimeout(() => {
            this.setState({ alert_type: '' });
        }, 4000);
    }

    changePasswordForFirstTimeLogin = (user, password) => {
        this.setState({ isLoading: true });

        Auth.completeNewPassword(
            user,               // the Cognito User Object
            password,       // the new password

        ).then(async (user) => {
            this.setState({ isLoading: false });
            this.setState({ isFirstTimeLogin: false });
            let currentSession = await Auth.currentSession();
            let idToken = currentSession.getIdToken().getJwtToken()
            localStorage.setItem('authenticationToken', idToken);
            this.props.history.push('/ServiceList');
        }).catch(err => {
            this.setState({ isLoading: false });
            this.setState({ isFirstTimeLogin: false });
        });
    }

    handleSubmit = async (event) => {
        try {
            localStorage.clear();
            event.preventDefault();
            this.clearAlertType();
            let email = this.state.email;
            let password = this.state.password;
            this.setState({ isLoading: true });

            awsPoolConfiguration.aws_project_region = region;
            awsPoolConfiguration.aws_cognito_identity_pool_id = identityPoolId;
            awsPoolConfiguration.aws_cognito_region = region;
            awsPoolConfiguration.aws_user_pools_id = userpoolID;
            awsPoolConfiguration.aws_user_pools_web_client_id = appClientID;

            Amplify.configure(awsPoolConfiguration);
            console.log('signIn before', new Date());
            Auth.signIn(email, password)
                .then(async (user) => {
                    console.log('signIn after', new Date());
                    this.setState({ isLoading: false });

                    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        if (this.state.newPassword !== "") {
                            this.changePasswordForFirstTimeLogin(user, this.state.newPassword);
                        } else {
                            this.setState({ isFirstTimeLogin: true });
                        }
                    }
                    else {
                        let currentSession = await Auth.currentSession();
                        let idToken = currentSession.getIdToken().getJwtToken()
                        localStorage.setItem('authenticationToken', idToken);
                        this.props.history.push('/ServiceList');
                    }
                }).catch(error => {
                    console.log('signIn err+++++++++', error);
                    this.setState({ isLoading: false });
                    alert(error);
                });
        } catch (error) {
            console.log('err.....', error);
        }
    }

    render() {
        return (
            <div>
                <div className="display_flex flex_wrap height_100p">
                    <div className="login_left_side blue_color_fifth_bg height_100p" >
                        <div className="width_100 height_100p padding_24 position_relative display_flex flex_align_center justify_center" >
                            <div className="logo">
                                <div className="display_flex justify_center">
                                    <img src={Logo} alt="" className="powered_logo" />
                                </div>
                                <div className="displa_flex justify_center margin_top_36">
                                    <img src={Tagline} alt="" className="powered_logo" />
                                </div>
                            </div>
                            <div className="powered_by font_12 muller_light">
                                Powered by<img src={StellosysLogo} alt="" className="powered_logo width_48" />
                                <span className="login_copyright"> Copyright 2021 Stellosys All rights Reserved.</span>
                            </div>
                        </div>
                    </div>

                    <div className="login_right_side fifth_blue_bg height_100p display_flex flex_align_center login_main">
                        <div className="form_section" >
                            <div className="login_alert_msg">
                                {
                                    this.state.alert_type === 'SUCCESS' ? (<SuccessAlert message={this.state.alert_message} />) : null
                                }

                                {
                                    this.state.alert_type === 'ERROR' ? (<ErrorAlert message={this.state.alert_message} />) : null
                                }
                            </div>



                            <div className="login_header hard_blue_color muller_bold margin_top_48 margin_bottom_24 font_24">
                                Login
                            </div>
                            <div className="field_section width_360">
                                <Form onSubmit={this.handleSubmit} autoComplete="off">

                                    <Form.Group controlId="formBasicEmail">
                                        <div className="input_custom_form_style">
                                            <Form.Label> Username</Form.Label>
                                        </div>
                                        <Form.Control type="email" name='email' required

                                            onChange={this.handleChange} value={this.state.email} className="input_style"
                                        />

                                    </Form.Group>

                                    <Form.Group controlId="formBasicPassword">
                                        <div className="input_custom_form_style">
                                            <Form.Label> Password</Form.Label>
                                        </div>

                                        <Form.Control type='password' name='password' required
                                            onChange={this.handleChange} className="input_style" />

                                        <div className="login_chkbox">
                                        </div>

                                    </Form.Group>

                                    {
                                        this.state.isFirstTimeLogin ? (
                                            <Form.Group controlId="formBasicResetPassword">
                                                <div className="input_custom_form_style">
                                                    <Form.Label> New Password</Form.Label>
                                                </div>
                                                <Form.Control
                                                    type="password"
                                                    name='newPassword' required="required"
                                                    placeholder="*******" onChange={this.handleChange} className="input_style"
                                                />
                                            </Form.Group>
                                        ) : null
                                    }


                                    <Form.Group className="sign-bottom-section" controlId="formBasicCheckbox" >
                                        <div className="chkbox_btn_align">
                                            <div className="chkbox">


                                            </div>

                                            <div className="signin_btn">
                                                <button className="login_submit_button font_12 muller_medium display_flex flex_align_center justify_center pointer position_relative" type="submit" disabled={this.state.isLoading}  >
                                                    LOGIN
                                                    {this.state.isLoading && (
                                                        <img src={Loader} className="spinner" alt="" />
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <div className="txt_blue_color font_12 pointer" onClick={(e) => { this.props.history.push('/ForgotPassword'); }}>
                                        Forgot Password?
                                    </div>
                                </Form>

                            </div>
                        </div>
                    </div >

                </div >

            </div>
        )
    }
}









