
import { getCountries } from '../graphql/country';
import { Amplify, graphqlOperation, API } from 'aws-amplify';

let countryAppsyncUrl = process.env.REACT_APP_COUNTRY_APPSYNC_URL;
let countryApiKey = process.env.REACT_APP_COUNTRY_API_KEY;
let GATSBY_REGION = process.env.REACT_APP_REGION;


const getCountry = async (objWhere) => {
    const countryConfig = {
        'aws_appsync_graphqlEndpoint': countryAppsyncUrl,
        'aws_appsync_region': GATSBY_REGION,
        'aws_appsync_authenticationType': 'API_KEY',
        'aws_appsync_apiKey': countryApiKey
    }

    console.log('countryConfig++++++++++++',JSON.stringify(countryConfig));

    Amplify.configure(countryConfig);

    return API.graphql(graphqlOperation(getCountries, { OperationList: objWhere }));
}

export {
    getCountry
}