import axios from 'axios';

let languageServiceUrl = process.env.REACT_APP_LANGUAGE_SERVICE_URL;

const getLanguageGuid = async (code) => {
    let apiURL = `${languageServiceUrl}/languageService/language`;
    
    let response = await axios.get(apiURL);
    let languageArr = response.data.dataObject;

    let languageObj = languageArr.find((obj) => {
        return obj.Code.toUpperCase() === code.toUpperCase();
    });

    return languageObj.LanguageId.toUpperCase();
}

const getAllLanguages = async (code) => {
    let apiURL = `${languageServiceUrl}/languageService/language`;
   
    let response = await axios.get(apiURL);
    let languageArr = response.data.dataObject;

    return languageArr;
}

export {
    getLanguageGuid,
    getAllLanguages
}