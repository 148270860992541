import axios from 'axios';
import { Auth } from 'aws-amplify';

let tenantIdentityUrl = process.env.REACT_APP_TENANT_IDENTITY_URL;

const getBuyLicenseList = async () => {
    try {
        let apiURL = `${tenantIdentityUrl}/tenantIdentity/getBuyLicenseList`;
        let token = await getToken();
        let headerParam = {
            "headers": {
                'Authorization': token,
                'language': 'en'
            }
        }

        return new Promise((resolve, reject) => {
            axios.get(apiURL, headerParam).then((response) => {
                console.log('getBuyLicenseList response++++++++++', response);
                resolve(response.data);
            }).catch((err) => {
                console.log('getBuyLicenseList err', err);
                reject(err);
            });
        });

    } catch (error) {
        console.log('getBuyLicenseList error', error);
    }
}

const getSubscriptionList = async () => {
    try {
        let apiURL = `${tenantIdentityUrl}/tenantIdentity/getSubscriptionList`;

        return new Promise((resolve, reject) => {
            axios.get(apiURL).then((response) => {
                console.log('Respons', response);
                resolve(response.data.dataObject);
            }).catch((err) => {
                console.log('getSubscriptionList err', err);
                reject(err);
            });

        });

    } catch (error) {
        console.log('getSubscriptionList error', error);
    }

}

const getToken = async () => {
    const currentSession = await Auth.currentSession();
    console.log('currentSession+++++++++++++', currentSession);
    console.log('token++++++++++', currentSession.getIdToken().getJwtToken())
    return currentSession.getIdToken().getJwtToken();
}

const getRegisteredTrialUserList = async () => {
    try {
        let apiURL = `${tenantIdentityUrl}/tenantIdentity/getRegisteredTrialUserList`;
        let token = await getToken();
        let headerParam = {
            "headers": {
                'Authorization': token,
                'language': 'en'
            }
        }

        return new Promise((resolve, reject) => {
            axios.get(apiURL, headerParam).then((response) => {
                console.log('getRegisteredTrialUserList response++++++++++', response);
                resolve(response.data);
            }).catch((err) => {
                console.log('getRegisteredTrialUserList err', err);
                reject(err);
            });
        });

    } catch (error) {
        console.log('getRegisteredTrialUserList error', error);
    }
}

const getFreeDemoList = async () => {
    try {
        let apiURL = `${tenantIdentityUrl}/tenantIdentity/getFreeDemoList`;
        let token = await getToken();
        let headerParam = {
            "headers": {
                'Authorization': token,
                'language': 'en'
            }
        }

        return new Promise((resolve, reject) => {
            axios.get(apiURL, headerParam).then((response) => {
                console.log('getFreeDemoList response++++++++++', response);
                resolve(response.data.returnResponse);
            }).catch((err) => {
                console.log('getFreeDemoList err', err);
                reject(err);
            });
        });

    } catch (error) {
        console.log('getFreeDemoList error', error);
    }
}

export {
    getBuyLicenseList,
    getSubscriptionList,
    getRegisteredTrialUserList,
    getFreeDemoList
}