import React, { useState, useEffect, useRef } from 'react';
import '../styles/emailcomponent.scss';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Logologipulse from '../images/Logologipulse.svg';
import Form from 'react-bootstrap/Form';
import { saveEmail, getReferenceName, getEmailByGuid, updateEmail } from '../services/logipulse-crm-service';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import ReactDOM from "react-dom";
import Editor from "@monaco-editor/react";
import Papa from "papaparse";

function EmailComponents() {
    const fullRef = React.useRef(null);
    const lessRef = React.useRef(null);
    const editorRef = useRef(null);
    const [htmlContent, setHtmlContent] = useState('');
    const [values, setValues] = useState([])

    const INITIAL_STATE = {
        From: "",
        To: "",
        Subject: "",
        Reference: "",
        Message: ""
    }
    const onAddClick = (e) => {
        fullRef.current.classList.toggle("width_100");
        lessRef.current.classList.toggle("d-none");
    };

    const [formValues, setformValues] = useState(INITIAL_STATE);
    const [referenceName, setReferenceName] = useState([]);

    const handleOnChange = (event) => {
        const value = { ...formValues };
        value[event.target.name] = event.target.value;
        setformValues(value);
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('form,,,,', formValues);

        if (formValues.hasOwnProperty('emailGuid')) {
            updateEmail(formValues).then((response) => {
                alert('Email Updated Successfully');
            }).catch((error) => {
                console.log('error', error);
            });
        } else {
            saveEmail(formValues).then((response) => {
                setformValues(INITIAL_STATE);
                alert('Email Saved Successfully');
                getReference();
            }).catch((error) => {
                console.log('error', error);
            });
        }

    }

    const resetValues = (e) => {
        setformValues(INITIAL_STATE);
    }
    useEffect(() => {
        getReference();

        return () => {
        };

    }, []);

    const getReference = async () => {
        try {
            let response = await getReferenceName();
            response = response.map((opt) => { return { label: opt.Reference, value: opt.emailGuid } });
            setReferenceName(response);

        } catch (error) {
            console.log('getReference error', error);
        }
    }
    const addFormData = async (event) => {
        let emailGuid = event.value;
        let response = await getEmailByGuid(emailGuid);
        console.log('response.........', response);
        setformValues(response);
    }

    const handleEditorDidMount = (editor, monaco) => {
        editorRef.current = editor;
    }

    const handleEditorChange = (value, event) => {
        const obj = { ...formValues };
        obj['Message'] = value;
        setformValues(obj);
    }

    const showValue = () => {
        let htmlEditorValue = editorRef.current.getValue();
        setHtmlContent(htmlEditorValue);
    }

    const changeHandler = e => {
        Papa.parse(e.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
                const valuesArray = [];
                
                results.data.map(d => {
                    valuesArray.push(Object.values(d))
                })
                console.log('valllllllllll',valuesArray);
                console.log('results.data',results.data);

                setValues(valuesArray);
                formValues.To = valuesArray.join(', ');
                setformValues(formValues);
                console.log('formValues.............',formValues);
            }
        })
    }

    return (
        <div className="email_component">
            <div className="header">
                <Navbar className="header_nav" expand="lg">
                    <Navbar.Brand href="#home">
                        <img src={Logologipulse} className="d-inline-block align-top" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {/* <Nav.Link href="/ServiceList">HOME</Nav.Link> */}
                            {/* <Nav.Link href="#home">COMPOSE</Nav.Link> */}
                            <Nav.Link href="#links">SENT</Nav.Link>
                            <Nav.Link href="#link">DRAFTS</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
            <div className="logout_btn">
                <Link to='/ServiceList'>
                    <div className="login_submit_button text-center">Back</div>{' '}
                </Link>
            </div>
            <div className="lp-body">
                <div className="display_flex">
                    <div ref={lessRef} className="left_section">
                        <div className="display_flex space_between margin_bottom_48">
                            <h2 className="heading">New mail</h2>
                            <div className="display_flex">
                                <Select className="select_width" options={referenceName}
                                    onChange={addFormData} />
                            </div>
                        </div>
                        <div>
                            <Form autoComplete="off" onSubmit={handleSubmit}>
                                <div className="display_flex space_between align_item_baseline">
                                    <Form.Group className="margin_bottom_24 margin_right_16 width_100" controlId="formBasicEmail">
                                        <Form.Label>From</Form.Label>
                                        <Form.Control type="email" name="From" value={formValues.From} required="required" onChange={handleOnChange} />
                                    </Form.Group>

                                    <Form.Group className="margin_bottom_24 width_100" controlId="formBasicEmailSecond">
                                        <div className="display_flex space_between align_item_center">
                                            <Form.Label>To</Form.Label>
                                            <div className="display_flex position_relative">
                                                <h6 className="margin_right_8 upload_links color_light_black margin_bottom_8">Or</h6>
                                                {/* <Form.Control type="file" className="file_type" /> */}
                                                {/* <CSVReader /> */}
                                                {/* <h6 className="upload_links color_blue margin_bottom_8">Upload CSV file</h6> */}
                                                <input type="file" name="file" onChange={changeHandler} accept=".csv"
                                                />
                                            </div>
                                        </div>
                                        <Form.Control type="email" name="To" value={formValues.To} required="required" onChange={handleOnChange} />
                                    </Form.Group>
                                </div>
                                <div className="display_flex space_between align_item_baseline">
                                    <Form.Group className="margin_bottom_24 margin_right_16 width_100" controlId="formBasicSubject">
                                        <Form.Label>Refferance Name</Form.Label>
                                        <Form.Control type="text" name="Reference" value={formValues.Reference} required="required" onChange={handleOnChange} />
                                    </Form.Group>
                                    <Form.Group className="margin_bottom_24 width_100" controlId="formBasicSubject">
                                        <Form.Label>Subject Text</Form.Label>
                                        <Form.Control type="text" name="Subject" value={formValues.Subject} required="required" onChange={handleOnChange} />
                                    </Form.Group>
                                </div>
                                <Form.Group className="margin_bottom_24" controlId="formBasicSubject">
                                    <div className="position_relative">
                                        <Form.Label>Message Content</Form.Label>
                                        {/* <div className="display_flex align_item_center">
                                            <button type="submit" className="btn_blue size_change margin_right_16 margin_bottom_8">
                                                Save
                                            </button>
                                            <Form.Control type="file" className="file_type" />
                                            <h6 className="upload_links color_blue margin_bottom_8">Choose Template</h6>
                                        </div> */}
                                        <Editor
                                            height="46vh"
                                            onMount={handleEditorDidMount}
                                            onChange={handleEditorChange}
                                            defaultLanguage="html"
                                            defaultValue={formValues.Message}
                                            value={formValues.Message}
                                        />
                                    </div>

                                    {/* <Form.Control as="textarea" name="Message" value={formValues.Message} required="required" onChange={handleOnChange} rows={11} /> */}
                                </Form.Group>
                                <div className="display_flex space_between">
                                    <button onClick={resetValues} className="btn_ash">
                                        Reset Fields
                                    </button>
                                    <div className="right_side">
                                        <div onClick={showValue} className="display_inline_flex btn_blue margin_right_16">
                                            Preview
                                        </div>
                                        <button type="submit" className="btn_blue margin_right_16">
                                            Save
                                        </button>
                                        <button className="btn_blue">
                                            Send Mail
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div ref={fullRef} className="right_section">
                        <div className="display_flex margin_bottom_48 space_between align_item_center">
                            <h2 className="heading">Preview</h2>
                            <h6 id="full_screen" onClick={onAddClick} className="upload_links color_blue cursor_pointer">
                                <span className="full_screen">Full Screen</span>
                                <span className="exit_full_screen">Exit Full Screen</span>
                            </h6>
                        </div>
                        <div className="preview_section">
                            <iframe srcDoc={htmlContent} frameborder="0" className="iframestyles" />
                        </div>
                        {/* <iframe> */}
                        {/* <div className="preview_section" dangerouslySetInnerHTML={{ __html: formValues.messagecontent}}/> */}
                        {/* </iframe> */}
                        {/* <iframe srcDoc={formValues.messagecontent} scrolling="no" frameborder="0" className="iframestyles"/> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmailComponents;
