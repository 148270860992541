import React, { useEffect, useState } from 'react';
import { getRegisteredTrialUserList } from "../services/tenant-identity-service";
import { Link } from 'react-router-dom';
import { getCountry } from '../services/country-service';
import { getLanguageGuid } from '../services/language-service';
import { getDecodedTokenDetails } from '../services/cognito-token-service';
import '../styles/loader.scss';
import Loader from '../images/transparent-loader.png';
import DataGrid, {
    Column, Pager,
    Paging,
    SearchPanel, Export,
    FilterRow
} from 'devextreme-react/data-grid';


let SearchParam = require('wherecomposer/dist/SearchParam').SearchParam;
let SearchOperationBuilder = require('wherecomposer/dist/SearchParam').SearchOperationBuilder;
let ConditionCreator = require('wherecomposer/dist/ConditionCreator').ConditionCreator;
const EventTypeEnum = require('../constant/entity-type').EventTypeEnum;

function FreeTrial(props) {
    const [freeTrialList, setFreeTrialList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const pageSizes = [10, 25, 50, 100];

    useEffect(() => {
        getFreeTrial();
        return () => {
        };

    }, []);

    const getFreeTrial = async () => {
        try {
            setLoading(true);
            let decodedToken = await getDecodedTokenDetails();
            let empl_id = decodedToken.empl_id;
            let countryList = await getCountries();
            let response = await getRegisteredTrialUserList();

            response.forEach((obj) => {
                if (obj.hasOwnProperty('countryGuid')) {
                    if (countryList) {
                        let selectedData = countryList.filter((countryObj) => {
                            return countryObj.GroupGuid.toLowerCase() === obj.countryGuid.toLowerCase()
                        });

                        if (selectedData.length > 0) {
                            Object.assign(obj, { 'Country': selectedData[0] });
                        } else {
                            Object.assign(obj, { 'Country': { Name: '' } })
                        }
                    }
                } else {
                    Object.assign(obj, { 'Country': { Name: '' } })
                }

                if (obj.hasOwnProperty('entity_type')) {
                    if (obj.entity_type == EventTypeEnum.BUY_LICENCE) {
                        Object.assign(obj, { 'entity_type_name': 'Buy License' });
                    } else if (obj.entity_type == EventTypeEnum.FREE_TRIAL) {
                        Object.assign(obj, { 'entity_type_name': 'Free Trial' });
                    }
                }

                if (obj.hasOwnProperty('Employee')) {
                    if (Object.keys(obj.Employee).length == 0) {
                        if (obj.empl_id == empl_id) {
                            let employeeObj = {
                                'userName': decodedToken.userName
                            }

                            Object.assign(obj, { 'Employee': employeeObj });
                        } else if (obj.empl_id == 0) {
                            let employeeObj = {
                                'userName': 'Online'
                            }

                            Object.assign(obj, { 'Employee': employeeObj });
                        }
                    }
                } else {
                    if (obj.empl_id == empl_id) {
                        let employeeObj = {
                            'userName': decodedToken.userName
                        }

                        Object.assign(obj, { 'Employee': employeeObj });
                    } else if (obj.empl_id == 0) {
                        let employeeObj = {
                            'userName': 'Online'
                        }

                        Object.assign(obj, { 'Employee': employeeObj });
                    }
                }
            });

            setFreeTrialList(response);
            setLoading(false);
        } catch (error) {
            console.log('getFreeTrial error', error);
            setLoading(false);
        }

    }

    const getCountryData = (objWhere) => {
        return new Promise((resolve, reject) => {
            getCountry(objWhere).then(data => {
                console.log('freeTrial country++++++++++', data);
                resolve(data);
            }).catch((error) => {
                console.log('getCountryData err.......', error)
                reject(error);
            });

        });

    }

    const getCountries = async () => {
        try {
            let searchParamArray = [];

            let languageGuid = await getLanguageGuid('en');
            let objSearchParam2 = new SearchParam();
            objSearchParam2.searchParamCreate("LanguageGuid", "Guid", languageGuid, SearchOperationBuilder.create(true, false, false), false);
            searchParamArray.push(objSearchParam2);
            let objCreator = new ConditionCreator();
            let result = objCreator.getSearchCondition(searchParamArray);
            let objWhere = result.toJsonString();

            let response = await getCountryData(objWhere);
            let countryList = response.data.getCountries
            return countryList;
        } catch (error) {
            console.log('Error');
        }

    }

    const calculateSerialNo = (cellInfo) => {
        return cellInfo.rowIndex + 1;
    }

    const handleRowPrepared = (e) => {
        if (e.rowType === "data" && e.data.entity_type === EventTypeEnum.BUY_LICENCE) {
            e.rowElement.style.backgroundColor = "#c1c1c1";
        }

        // if (e.rowType === "data" && e.data.entity_type === EventTypeEnum.FREE_TRIAL) {
        //     e.rowElement.style.backgroundColor = "#999999";
        // }
    };

    return (
        <div className="App">
            <div className="logout_btn">
                <Link to='/ServiceList'>
                    <div className="login_submit_button text-center">Back</div>{' '}
                </Link>
            </div>
            <DataGrid
                dataSource={freeTrialList}
                keyExpr="freeTrialInfoGuid"
                allowColumnResizing={true}
                focusedRowEnabled={true}
                wordWrapEnabled={true}
                focusedRowKey={1}
                allowColumnReordering={true}
                onRowPrepared={handleRowPrepared}
                showBorders={true}>
                <Paging enabled={false} />
                <Export enabled={true} />
                <FilterRow visible={true} />
                <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                <Paging defaultPageSize={50} />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Column
                    caption="SI NO"
                    cellRender={calculateSerialNo}
                />
                <Column
                    dataField="entity_type_name"
                    caption="Type"
                />
                <Column
                    dataField="fullName"
                    caption="Name"
                />
                <Column
                    dataField="companyName"
                    caption="Company Name"
                />
                <Column
                    dataField="email"
                    caption="Email"
                />
                <Column
                    dataField="phone_number"
                    caption="Mobile No"
                />
                <Column
                    dataField="Country.Name"
                    caption="Country"
                />
                <Column
                    dataField="createdDate"
                    caption="Created Date"
                    dataType="datetime"
                />
                <Column
                    dataField="Employee.userName"
                    caption="Employee"
                />
                <Column
                    dataField="empl_id"
                    caption="Employee Id"
                />
                <Column
                    dataField="campaign_id"
                    caption="Campaign Id"
                />
            </DataGrid>
            {
                isLoading ? (
                    <img src={Loader} alt="" className="loader" />
                ) : null
            }
        </div>
    )

}

export default FreeTrial
