import React, { useState, useRef, useEffect } from 'react';
import '../styles/emailcomponent.scss';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Logologipulse from '../images/Logologipulse.svg';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { getBroadcastList, saveBroadcastName, getBroadcastGuid } from '../services/logipulse-crm-service';
import Form from 'react-bootstrap/Form';

const INITIAL_STATE = {
    broadcastName: ""
}

function Broadcastlist(props) {

    const showDiv = React.useRef(null);
    const [broadCastList, setBroadCastList] = useState([]);
    const [formValues, setformValues] = useState(INITIAL_STATE);

    const showClick = (e) => {
        showDiv.current.classList.remove("d-none");
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        saveBroadcastName(formValues).then((response) => {
            alert('BroadCastName Saved Successfully');
        }).catch((error) => {
            console.log('error', error);
        });
    }

    const handleOnChange = (event) => {
        const value = { ...formValues };
        value[event.target.name] = event.target.value;
        setformValues(value);
    }

    const addFormData = async (event) => {
        let broadcastGuid = event.value;
        let response = await getBroadcastGuid(broadcastGuid);
        console.log('response.........', response);
        setformValues(response);
    }

    useEffect(() => {
        getBroadCastItems();

        return () => {
        };

    }, []);

    const getBroadCastItems = async () => {
        try {
            let response = await getBroadcastList();
            response = response.map((opt) => { return { label: opt.broadcastName, value: opt.broadcastGuid } });
            console.log(response);
            setBroadCastList(response);

        } catch (error) {
            console.log('getReference error', error);
        }
    }

    return (
        <div className="email_component">
            <div className="header">
                <Navbar className="header_nav" expand="lg">
                    <Navbar.Brand href="#home">
                        <img src={Logologipulse} className="d-inline-block align-top" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
            <div className="logout_btn">
                <Link to='/ServiceList'>
                    <div className="login_submit_button text-center">Back</div>{' '}
                </Link>
            </div>
            <div className="lp-body center_align_div">
                <div className="">

                    <div className="margin_bottom_24">
                        <div className="color_light_black font_16">
                            Broadcast Name List
                        </div>
                        <div className="display_flex margin_bottom_24">
                            <Select className="select_width margin_right_16" options={broadCastList}
                                onChange={addFormData} />
                            <button className="btn_blue" onClick={(e)=>{props.history.push('/EmailComponents')}}>
                                Go
                            </button>
                        </div>
                    </div>
                    <div className="">
                        <div className="text_center margin_bottom_24 cursor_pointer color_blue font_16" onClick={showClick}>
                            Create New Broadcast Name
                        </div>
                        <div ref={showDiv} className="position_relative d-none">
                            <Form className="display_flex align_item_self_end" autoComplete="off" onSubmit={handleSubmit}>
                                <Form.Group className="margin_right_16" controlId="formBasicSubject">
                                    <Form.Label className="color_light_black font_16">Broadcast Name</Form.Label>
                                    <Form.Control className="width_250" value={formValues.broadcastName} type="text" name="broadcastName" required="required" onChange={handleOnChange} />
                                </Form.Group>
                                <button type="submit" className="btn_blue">
                                    Submit
                                </button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Broadcastlist
