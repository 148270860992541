import React from 'react';
import "../styles/contactUs.scss";
import "../styles/loginpage.scss";
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';

function ServiceList(props) {

    const logOut = async (event) => {
        event.preventDefault();
        try {
            Auth.signOut();
            props.history.push('/login');
        } catch (error) {
            console.log('signout err...', error);
        }
    }

    return (
        <div className="service_list">
            <div className="logout_btn">
                <div className="login_submit_button text-center cursor_pointer" onClick={logOut}>Logout</div>
            </div>
            <div className="App item_center">
                <div className="Contact_button margin_right_16">
                    <Link to='/contactUs'>
                        <div className="login_submit_button text-center">Contact Us</div>{' '}
                    </Link>
                </div>
                <div className="Free_button margin_right_16">
                    <Link to='/FreeTrialInfo'>
                        <div className="login_submit_button text-center">Free Trial & Buy License</div>{' '}
                    </Link>
                </div>
                <div className="Subscribe_button margin_right_16">
                    <Link to='/Subscribe'>
                        <div className="login_submit_button text-center">Subscribe</div>{' '}
                    </Link>
                </div>
                <div className="Buy_licence margin_right_16">
                    <Link to='/BuyLicense'>
                        <div className="login_submit_button text-center">Buy License</div>{' '}
                    </Link>
                </div>
                <div className="Buy_licence margin_right_16">
                    <Link to='/FreeDemoInfo'>
                        <div className="login_submit_button text-center">Free Demo</div>{' '}
                    </Link>
                </div>
                {/* <div className="Email_components margin_right_16">
                    <Link to='/BroadcastList'>
                        <div className="login_submit_button text-center">Create Broadcast</div>{' '}
                    </Link>
                </div> */}
                <div className="Subscribe_button margin_right_16">
                    <Link to='/ChangePassword'>
                        <div className="login_submit_button text-center">Change Password</div>{' '}
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ServiceList
