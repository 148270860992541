import React, { Component } from 'react';
import { Fragment } from 'react';


export default class SuccessAlert extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showAlertMessage: false
        }
    }

    componentDidMount = () => {

        if (this.props.message !== null) {
            this.setState({ showAlertMessage: true })
        }

        setTimeout(() => this.setState({ showAlertMessage: false }), 3000);
    }

    render() {
        
        return (
            <Fragment>
                {
                    this.state.showAlertMessage ? (
                        <div className="alert alert-success" role="alert" style={{ marginTop: "40px" }}>
                            {this.props.message}
                        </div>
                    ) : null
                }
            </Fragment>
        )
    }
};