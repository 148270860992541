import React, { Fragment } from 'react';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import {
    Validator,
    RequiredRule,
    CompareRule,
    EmailRule,
} from 'devextreme-react/validator';

import notify from 'devextreme/ui/notify';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { Auth } from 'aws-amplify';
import { MessageType } from '../constant/message_type_enum';
import "../styles/change_password.scss";
import { Link } from 'react-router-dom';

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userName: '',
            oldPassword: '',
            newPassword: '',
            loadIndicatorVisible: false,
            newPasswordMode: 'password',
            oldPasswordMode: 'password',
            confirmPasswordMode: 'password',
        };


        this.newPasswordButton = {
            icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7klEQVRYw+2YP0tcQRTFz65xFVJZpBBS2O2qVSrRUkwqYfUDpBbWQu3ELt/HLRQ/Q8RCGxVJrRDEwj9sTATxZ/Hugo4zL/NmV1xhD9xi59177pl9986fVwLUSyi/tYC+oL6gbuNDYtyUpLqkaUmfJY3a+G9JZ5J2JW1J2ivMDBSxeWCfeBxYTHSOWMcRYLOAEBebxtEVQWPASQdi2jgxro4E1YDTQIJjYM18hszGbew4EHNq/kmCvgDnHtI7YBko58SWgSXg1hN/btyFBM0AlwExczG1YDZrMS4uLUeUoDmgFfjLGwXEtG05wNXyTc4NXgzMCOAIGHD8q0ATuDZrempkwGJ9+AfUQ4K+A/eEseqZ/UbgdUw4fqs5vPeW+5mgBvBAPkLd8cPju+341P7D/WAaJGCdOFQI14kr6o/zvBKZYz11L5Okv5KGA89Kzu9K0b0s5ZXt5PjuOL6TRV5ZalFP4F+rrnhZ1Cs5vN6ijmn7Q162/ThZq9+YNW3MbfvDAOed5cxdGL+RFaUPKQtjI8DVAr66/u9i6+jJzTXm+HFEVqxVYBD4SNZNKzk109HxoycPaG0bIeugVDTp4hH2qdXJDu6xOAAWiuQoQdLHhvY1aEZSVdInG7+Q9EvSz9RrUKqgV0PP3Vz7gvqCOsUj+CxC9LB1Dc8AAAASdEVYdEVYSUY6T3JpZW50YXRpb24AMYRY7O8AAAAASUVORK5CYII=',
            type: 'default',
            onClick: () => {
                this.setState({
                    newPasswordMode: (this.state.newPasswordMode === 'text' ? 'password' : 'text'),
                });
            },
        };

        this.oldPasswordButton = {
            icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7klEQVRYw+2YP0tcQRTFz65xFVJZpBBS2O2qVSrRUkwqYfUDpBbWQu3ELt/HLRQ/Q8RCGxVJrRDEwj9sTATxZ/Hugo4zL/NmV1xhD9xi59177pl9986fVwLUSyi/tYC+oL6gbuNDYtyUpLqkaUmfJY3a+G9JZ5J2JW1J2ivMDBSxeWCfeBxYTHSOWMcRYLOAEBebxtEVQWPASQdi2jgxro4E1YDTQIJjYM18hszGbew4EHNq/kmCvgDnHtI7YBko58SWgSXg1hN/btyFBM0AlwExczG1YDZrMS4uLUeUoDmgFfjLGwXEtG05wNXyTc4NXgzMCOAIGHD8q0ATuDZrempkwGJ9+AfUQ4K+A/eEseqZ/UbgdUw4fqs5vPeW+5mgBvBAPkLd8cPju+341P7D/WAaJGCdOFQI14kr6o/zvBKZYz11L5Okv5KGA89Kzu9K0b0s5ZXt5PjuOL6TRV5ZalFP4F+rrnhZ1Cs5vN6ijmn7Q162/ThZq9+YNW3MbfvDAOed5cxdGL+RFaUPKQtjI8DVAr66/u9i6+jJzTXm+HFEVqxVYBD4SNZNKzk109HxoycPaG0bIeugVDTp4hH2qdXJDu6xOAAWiuQoQdLHhvY1aEZSVdInG7+Q9EvSz9RrUKqgV0PP3Vz7gvqCOsUj+CxC9LB1Dc8AAAASdEVYdEVYSUY6T3JpZW50YXRpb24AMYRY7O8AAAAASUVORK5CYII=',
            type: 'default',
            onClick: () => {
                this.setState({
                    oldPasswordMode: (this.state.oldPasswordMode === 'text' ? 'password' : 'text'),
                });
            },
        };

        this.confirmPasswordButton = {
            icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7klEQVRYw+2YP0tcQRTFz65xFVJZpBBS2O2qVSrRUkwqYfUDpBbWQu3ELt/HLRQ/Q8RCGxVJrRDEwj9sTATxZ/Hugo4zL/NmV1xhD9xi59177pl9986fVwLUSyi/tYC+oL6gbuNDYtyUpLqkaUmfJY3a+G9JZ5J2JW1J2ivMDBSxeWCfeBxYTHSOWMcRYLOAEBebxtEVQWPASQdi2jgxro4E1YDTQIJjYM18hszGbew4EHNq/kmCvgDnHtI7YBko58SWgSXg1hN/btyFBM0AlwExczG1YDZrMS4uLUeUoDmgFfjLGwXEtG05wNXyTc4NXgzMCOAIGHD8q0ATuDZrempkwGJ9+AfUQ4K+A/eEseqZ/UbgdUw4fqs5vPeW+5mgBvBAPkLd8cPju+341P7D/WAaJGCdOFQI14kr6o/zvBKZYz11L5Okv5KGA89Kzu9K0b0s5ZXt5PjuOL6TRV5ZalFP4F+rrnhZ1Cs5vN6ijmn7Q162/ThZq9+YNW3MbfvDAOed5cxdGL+RFaUPKQtjI8DVAr66/u9i6+jJzTXm+HFEVqxVYBD4SNZNKzk109HxoycPaG0bIeugVDTp4hH2qdXJDu6xOAAWiuQoQdLHhvY1aEZSVdInG7+Q9EvSz9RrUKqgV0PP3Vz7gvqCOsUj+CxC9LB1Dc8AAAASdEVYdEVYSUY6T3JpZW50YXRpb24AMYRY7O8AAAAASUVORK5CYII=',
            type: 'default',
            onClick: () => {
                this.setState({
                    confirmPasswordMode: (this.state.confirmPasswordMode === 'text' ? 'password' : 'text'),
                });
            },
        };

        this.isComponentMounted = false;
        this.passwordComparison = this.passwordComparison.bind(this);
        this.onNewPasswordChanged = this.onNewPasswordChanged.bind(this);
        this.onOldPasswordChanged = this.onOldPasswordChanged.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    async componentDidMount() {
        this.isComponentMounted = true;

        const { attributes } = await Auth.currentAuthenticatedUser();
        if (this.isComponentMounted) {
            this.setState({
                userName: attributes.email,
            });
        }
    }

    componentWillUnmount() {
        this.isComponentMounted = false;
    }

    passwordComparison() {
        return this.state.newPassword;
    }

    onNewPasswordChanged(e) {
        this.setState({
            newPassword: e.value,
        });
    }

    onOldPasswordChanged(e) {
        this.setState({
            oldPassword: e.value,
        });
    }

    onUserNameChanged = (e) => {
        this.setState({
            userName: e.value,
        });
    }

    onFormSubmit(e) {
        try {
            e.preventDefault();
            this.setState({
                loadIndicatorVisible: true
            });
            const { oldPassword, newPassword } = this.state;

            Auth.currentAuthenticatedUser()
                .then(user => {
                    return Auth.changePassword(user, oldPassword, newPassword);
                })
                .then((data) => {
                    this.setState({
                        loadIndicatorVisible: false
                    })
                    this.displayCustomMessage('Password Changed Successfully', MessageType.SUCCESS);
                    this.props.history.push('/ServiceList');
                })
                .catch((err) => {
                    this.setState({
                        loadIndicatorVisible: false,
                    });
                    this.displayCustomMessage(err.message, MessageType.ERROR);
                });
        } catch (err) {
            this.displayCustomMessage(err.message, MessageType.ERROR);
            this.setState({
                loadIndicatorVisible: false,
            });
        }
    }

    displayCustomMessage(message, messageType) {
        notify({
            message: message,
            position: {
                my: 'center top',
                at: 'center top',
            },
        }, messageType, 5000);
    }

    render() {
        return (
            <div className="App">
                <div className="logout_btn">
                    <Link to='/ServiceList'>
                        <div className="login_submit_button text-center">Back</div>
                    </Link>
                </div>
                <form onSubmit={this.onFormSubmit}>
                    <div className="change_password_container">
                        <div className="dx-fieldset width_50_percent">
                            <div className="dx-fieldset-header">Change Password</div>
                            <div className="dx-field">
                                <div className="dx-field-label">Email</div>
                                <div className="dx-field-value">
                                    <TextBox value={this.state.userName}
                                        onValueChanged={this.onUserNameChanged} disabled={true}>
                                        <Validator>
                                            <RequiredRule message="Email is required" />
                                            <EmailRule message="Email is invalid" />
                                        </Validator>
                                    </TextBox>
                                </div>
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label">Old Password</div>
                                <div className="dx-field-value">
                                    <TextBox
                                        mode={this.state.oldPasswordMode}
                                        value={this.state.oldPassword}
                                        onValueChanged={this.onOldPasswordChanged}>
                                        <TextBoxButton
                                            name="password"
                                            location="after"
                                            options={this.oldPasswordButton}
                                        />
                                        <Validator>
                                            <RequiredRule message="Old Password is required" />
                                        </Validator>
                                    </TextBox>
                                </div>
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label">New Password</div>
                                <div className="dx-field-value">
                                    <TextBox
                                        mode={this.state.newPasswordMode}
                                        value={this.state.newPassword}
                                        onValueChanged={this.onNewPasswordChanged}>
                                        <TextBoxButton
                                            name="password"
                                            location="after"
                                            options={this.newPasswordButton}
                                        />
                                        <Validator>
                                            <RequiredRule message="New Password is required" />
                                        </Validator>
                                    </TextBox>
                                </div>
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label">Confirm Password</div>
                                <div className="dx-field-value">
                                    <TextBox
                                        mode={this.state.confirmPasswordMode}>
                                        <TextBoxButton
                                            name="password"
                                            location="after"
                                            options={this.confirmPasswordButton}
                                        />
                                        <Validator>
                                            <RequiredRule message="Confirm Password is required" />
                                            <CompareRule message="New Password and Confirm Password do not match" comparisonTarget={this.passwordComparison} />
                                        </Validator>
                                    </TextBox>
                                </div>
                            </div>
                        </div>

                        <div className="dx-fieldset">
                            <Button
                                id="button"
                                text="Submit"
                                type="success"
                                width={180}
                                height={40}
                                useSubmitBehavior={true} >
                                <LoadIndicator className="button-indicator" visible={this.state.loadIndicatorVisible} />
                                <span className="dx-button-text">Submit</span>
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default ChangePassword;
