import 'devextreme/dist/css/dx.light.css';
import Login from './pages/Login';
import ContactUs from './pages/contactUs';
import ServiceList from './pages/ServiceList';
import FreeTrial from './pages/FreeTrial';
import Subscribe from './pages/Subscribe';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React from 'react';
import BuyLicense from './pages/BuyLicense';
import EmailComponents from './pages/EmailComponents';
import ChangePassword from './pages/ChangePassword';
import BroadcastList from './pages/BroadcastList';
import ForgotPassword from './pages/forgot_password';
import FreeTrialInfo from './pages/FreeTrialInfo';
import FreeDemoInfo from './pages/FreeDemoInfo';
import Campaign from './pages/Campaign';
import awsPoolConfiguration from "./aws-exports";
import { Auth, Amplify } from "aws-amplify";
import "./styles/common_style.scss";

const REACT_APP_USERPOOL_REGION = process.env.REACT_APP_USERPOOL_REGION;
const REACT_APP_APPCLIENT_ID = process.env.REACT_APP_APPCLIENT_ID;
const REACT_APP_IDENTITY_POOL_ID = process.env.REACT_APP_IDENTITY_POOL_ID;
const REACT_APP_USERPOOL_ID = process.env.REACT_APP_USERPOOL_ID;

export default class App extends React.Component {
  constructor(props) {
    super(props);
    console.log('REACT_APP_ENV_FILE_NAME',process.env.REACT_APP_ENV_FILE_NAME)
  }

  componentDidMount = async () => {
    try {
      awsPoolConfiguration.aws_project_region = REACT_APP_USERPOOL_REGION;
      awsPoolConfiguration.aws_cognito_identity_pool_id = REACT_APP_IDENTITY_POOL_ID;
      awsPoolConfiguration.aws_cognito_region = REACT_APP_USERPOOL_REGION;
      awsPoolConfiguration.aws_user_pools_id = REACT_APP_USERPOOL_ID;
      awsPoolConfiguration.aws_user_pools_web_client_id = REACT_APP_APPCLIENT_ID;

      Amplify.configure(awsPoolConfiguration);

      Auth.currentSession().then(async (session) => {
        console.log('currentSession++++++++++', session)
      }).catch((error) => {
        console.log('currentSession err ************', error);
      });
    } catch (error) {
      console.log('configurator error', error);
    }
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact strict path="/" render={(props) => <Login {...props} />} />
          <Route exact strict path="/login" render={(props) => <Login {...props} />} />
          <Route exact strict path="/ServiceList" render={(props) => <ServiceList {...props} />} />
          <Route exact strict path="/contactUs" render={(props) => <ContactUs {...props} />} />
          <Route exact strict path="/FreeTrial" render={(props) => <FreeTrial {...props} />} />
          <Route exact strict path="/Subscribe" render={(props) => <Subscribe {...props} />} />
          <Route exact strict path="/Buylicense" render={(props) => <BuyLicense {...props} />} />
          <Route exact strict path="/BroadcastList" render={(props) => <BroadcastList {...props} />} />
          <Route exact strict path="/EmailComponents" render={(props) => <EmailComponents {...props} />} />
          <Route exact strict path="/ChangePassword" render={(props) => <ChangePassword {...props} />} />
          <Route exact strict path="/ForgotPassword" render={(props) => <ForgotPassword {...props} />} />
          <Route exact strict path="/FreeTrialInfo" render={(props) => <FreeTrialInfo {...props} />} />
          <Route exact strict path="/FreeDemoInfo" render={(props) => <FreeDemoInfo {...props} />} />
          <Route exact strict path="/Campaign" render={(props) => <Campaign {...props} />} />
        </Switch>
      </Router>
    )
  }
}
