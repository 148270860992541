import axios from 'axios';
import { Auth } from 'aws-amplify';

let trialtTenantIdentityUrl = process.env.REACT_APP_TRIAL_TENANT_IDENTITY_URL;

const getFreeTrialDetails = async () => {
    try {
        let apiURL = `${trialtTenantIdentityUrl}/trialTenantIdentity/getFreeTrialList`;
        console.log('apiURL+++++++++', apiURL)
        let token = await getToken();
        let headerParam = {
            "headers": {
                'Authorization': token,
                'language': 'en'
            }
        }

        return new Promise((resolve, reject) => {
            axios.get(apiURL, headerParam).then((response) => {
                console.log('getFreeTrialList Respons', response);
                resolve(response.data);
            }).catch((err) => {
                console.log('getFreeTrialDetails err', err);
                reject(err);
            });

        });

    } catch (error) {
        console.log('getFreeTrialDetails error', error);
    }

}

const getToken = async () => {
    const currentSession = await Auth.currentSession();
    console.log('currentSession+++++++++++++', currentSession);
    console.log('token++++++++++', currentSession.getIdToken().getJwtToken())
    return currentSession.getIdToken().getJwtToken();
}

export {
    getFreeTrialDetails
}