import React, { useEffect, useState } from 'react';
import { getFreeDemoList } from "../services/tenant-identity-service";
import { Link } from 'react-router-dom';
import { getCountry } from '../services/country-service';
import '../styles/loader.scss';
import Loader from '../images/transparent-loader.png';
import DataGrid, {
    Column, Pager,
    Paging,
    SearchPanel, Export,
    FilterRow
} from 'devextreme-react/data-grid';


let SearchParam = require('wherecomposer/dist/SearchParam').SearchParam;
let SearchOperationBuilder = require('wherecomposer/dist/SearchParam').SearchOperationBuilder;
let ConditionCreator = require('wherecomposer/dist/ConditionCreator').ConditionCreator;

function Campaign(props) {
    const [freeDemoList, setFreeDemoList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const pageSizes = [10, 25, 50, 100];

    useEffect(() => {
        getFreeTrial();
        return () => {
        };

    }, []);

    const getFreeTrial = async () => {
        try {
            setLoading(true);
            let response = await getFreeDemoList();
            console.log('getFreeDemoList response', response);

            setFreeDemoList(response);
            setLoading(false);
        } catch (error) {
            console.log('getFreeTrial error', error);
            setLoading(false);
        }

    }

    const calculateSerialNo = (cellInfo) => {
        return cellInfo.rowIndex + 1;
    }

    return (
        <div className="App">
            <div className="logout_btn">
                <Link to='/ServiceList'>
                    <div className="login_submit_button text-center">Back</div>{' '}
                </Link>
            </div>
            <DataGrid
                dataSource={freeDemoList}
                keyExpr="calendly_info_guid"
                allowColumnResizing={true}
                focusedRowEnabled={true}
                wordWrapEnabled={true}
                focusedRowKey={1}
                allowColumnReordering={true}
                showBorders={true}>
                <Paging enabled={false} />
                <Export enabled={true} />
                <FilterRow visible={true} />
                <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                <Paging defaultPageSize={50} />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Column
                    caption="SI NO"
                    cellRender={calculateSerialNo}
                />
                <Column
                    dataField="fullName"
                    caption="Name"
                />
                <Column
                    dataField="companyName"
                    caption="Company Name"
                />
                <Column
                    dataField="email"
                    caption="Email"
                />
                <Column
                    dataField="phone_number"
                    caption="Mobile No"
                />
                <Column
                    dataField="location"
                    caption="Location"
                />
                <Column
                    dataField="suggestion"
                    caption="Suggestion"
                />
                <Column
                    dataField="text_message_number"
                    caption="Text Message No"
                />
                <Column
                    dataField="createdDate"
                    caption="Created Date"
                    dataType="datetime"
                />
                <Column
                    dataField="Employee.userName"
                    caption="Employee"
                />
                <Column
                    dataField="empl_id"
                    caption="Employee Id"
                />
                <Column
                    dataField="campaign_id"
                    caption="Campaign Id"
                />
            </DataGrid>
            {
                isLoading ? (
                    <img src={Loader} alt="" className="loader" />
                ) : null
            }
        </div>
    )

}

export default Campaign
