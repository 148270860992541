import axios from 'axios';

let logipulseCRMUrl = process.env.REACT_APP_LOGIPULSE_CRM_URL;

const getContactDetails = async () => {
    try {
        let apiURL = `${logipulseCRMUrl}/logipulseCRM/listContactUsDetails`;

        return new Promise((resolve, reject) => {
            axios.get(apiURL).then((response) => {
                console.log('Respons', response);
                resolve(response.data.dataObject);
            }).catch((err) => {
                console.log('getContactDetails err', err);
                reject(err);
            });

        });

    } catch (error) {
        console.log('getContactDetails error', error);
    }

}

const getReferenceName = async () => {
    try {
        let apiURL = `${logipulseCRMUrl}/logipulseCRM/listEmailDetails`;

        return new Promise((resolve, reject) => {
            axios.get(apiURL).then((response) => {
                console.log('Respons', response);
                resolve(response.data.dataObject);
            }).catch((err) => {
                console.log('getReferenceName err', err);
                reject(err);
            });

        });

    } catch (error) {
        console.log('getReferenceName error', error);
    }

}

const saveEmail = async (inputData) => {
    let apiURL = `${logipulseCRMUrl}/logipulseCRM/saveEmail`;
    let response = await axios.post(apiURL, inputData);
    return response;
}

const getEmailByGuid = async (emailGuid) => {
    try {
        let apiURL = `${logipulseCRMUrl}/logipulseCRM/getEmailByGuid/${emailGuid}`;

        return new Promise((resolve, reject) => {
            axios.get(apiURL).then((response) => {
                console.log('Respons', response);
                resolve(response.data.dataObject);
            }).catch((err) => {
                console.log('err', err);
                reject(err);
            });

        });

    } catch (error) {
        console.log('error', error);
    }

}

const updateEmail = async (inputData) => {
    let apiURL = `${logipulseCRMUrl}/logipulseCRM/updateEmail`;
    let response = await axios.put(apiURL, inputData);
    return response;
}

const saveBroadcastName = async (inputData) => {
    let apiURL = `${logipulseCRMUrl}/logipulseCRM/saveBroadcastName`;
    let response = await axios.post(apiURL, inputData);
    return response;
}

const getBroadcastList = async () => {
    try {
        let apiURL = `${logipulseCRMUrl}/logipulseCRM/listBroadcastName`;

        return new Promise((resolve, reject) => {
            axios.get(apiURL).then((response) => {
                console.log('Respons', response);
                resolve(response.data.dataObject);
            }).catch((err) => {
                console.log('getBroadcastList err', err);
                reject(err);
            });

        });

    } catch (error) {
        console.log('getReferenceName error', error);
    }

}

const getBroadcastGuid = async (broadcastGuid) => {
    try {
        let apiURL = `${logipulseCRMUrl}/logipulseCRM/getBroadcastGuid/${broadcastGuid}`;

        return new Promise((resolve, reject) => {
            axios.get(apiURL).then((response) => {
                console.log('Respons', response);
                resolve(response.data.dataObject);
            }).catch((err) => {
                console.log('err', err);
                reject(err);
            });

        });

    } catch (error) {
        console.log('error', error);
    }

}

export {
    getContactDetails,
    getReferenceName,
    getEmailByGuid,
    saveEmail,
    updateEmail,
    getBroadcastList,
    saveBroadcastName,
    getBroadcastGuid
}